<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <template v-if="!loadingLandingPage && dataBusiness !== null">
      <div>
        <main role="main" class="flex-shrink-0 mb-5">
          <div class="header-row">
            <div class="container">
              <div class="row">
                <div class="col">
                  <h1 class="text-white">{{ dataBusiness.name }}</h1>
                  <div class="d-flex text-white align-items-center">
                    <div class="star-container d-flex">
                      <div class="rating-count">
                        {{ dataBusiness.average }}
                      </div>
                      <div class="star-count mr-2">
                        <div class="rating-upper" :style="'width:' + starWidth + '%'"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>
                        <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>
                      </div>
                    </div>

                    <div class="review-count">
                      {{ dataBusiness.total }} Reviews
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container bg-white">
            <ReviewSites v-if="showReviewSites" :business="dataBusiness.business" />
            <div v-else class="row">
              <div class="col my-4 font-weight-bold">
                <h2 class="text-center mb-3">{{ dataBusiness.review_gate_settings.body_title }}</h2>
                <div class="main-text text-center">
                  {{ dataBusiness.review_gate_settings.body_text }}
                </div>
                <div class="thumbs mb-5">
                  <div class="d-flex justify-content-center">
                    <div class="thumbs-up text-center py-4 px-4">
                      <button class="btn btn-link" type="button" @click="recordEmotions(1,0)">
                        <svg style="width:110px;height:110px;color:#599b5a" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M23,10C23,8.89 22.1,8 21,8H14.68L15.64,3.43C15.66,3.33 15.67,3.22 15.67,3.11C15.67,2.7 15.5,2.32 15.23,2.05L14.17,1L7.59,7.58C7.22,7.95 7,8.45 7,9V19A2,2 0 0,0 9,21H18C18.83,21 19.54,20.5 19.84,19.78L22.86,12.73C22.95,12.5 23,12.26 23,12V10M1,21H5V9H1V21Z" />
                        </svg>
                        <span class="d-block pt-1 font-weight-bold">{{ dataBusiness.review_gate_settings.good_label }}</span>
                      </button>
                    </div>
                    <div class="thumbs-down text-center py-4 px-4">
                      <button @click="recordEmotions(0,1)">
                        <svg style="width:110px;height:110px;color:#e85f5f" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M19,15H23V3H19M15,3H6C5.17,3 4.46,3.5 4.16,4.22L1.14,11.27C1.05,11.5 1,11.74 1,12V14A2,2 0 0,0 3,16H9.31L8.36,20.57C8.34,20.67 8.33,20.77 8.33,20.88C8.33,21.3 8.5,21.67 8.77,21.94L9.83,23L16.41,16.41C16.78,16.05 17,15.55 17,15V5C17,3.89 16.1,3 15,3Z" />
                        </svg>
                        <span class="d-block pt-1 font-weight-bold">{{ dataBusiness.review_gate_settings.bad_label }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-row row">
              <div class="col">
                {{ dataBusiness.business.address }}, {{ dataBusiness.business.city }}, {{ dataBusiness.business.state }}
                {{ dataBusiness.business.zip }} | {{ dataBusiness.business.business_phone }}
              </div>
            </div>
          </div>
        </main>
        <b-modal v-model="showNegativeReviewModal" modal-class="neg-feedback" bo title-html="" :hide-footer="true" :hide-header="true" :hide-header-close="true">
          <h5 class="text-center">Please leave your feedback below</h5>
          <TextInput v-model="negativeReviewForm.name" label="Name" />
          <TextInput v-model="negativeReviewForm.email" label="Email" />
          <label>Feedback</label>
          <BTextarea v-model="negativeReviewForm.feedback" rows="6" />
          <button :disabled="negativeFormWorking" class="btn btn-primary btn-sm mt-4 rounded-lg w-100 py-2" @click="submitNegativeExperience">
            <b-spinner v-if="negativeFormWorking" small class="mr-2"/> Submit</button>
          <div v-if="submissionMessage !== ''" class="feedback">{{ submissionMessage }}</div>
          <div class="skip-gate">If you prefer to just leave a review, <a :href="reviewLink">click here</a></div>
        </b-modal>
      </div>
    </template>
    <div v-else-if="status === 404" class="d-flex align-items-center justify-content-center vh-100 bg-primary m-0 p-0 error-div">
      <h1 class="display-4 fw-bold text-white text-center">
        <!-- eslint-disable vue/camelcase -->
        <span v-if="business_id.startsWith('card_')">
          Card Not Activated
          <span class="d-block font-size-20 mt-3">Please contact your sales rep to get your card setup and activated for your account</span>
        </span>
        <span v-else>
          Business Not Found
        </span>
      </h1>
    </div>
    <div v-else class="text-center mt-4">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>

import ReviewSites from "@components/Business/ReviewSites.vue";

export default {
  components:{
    ReviewSites
  },
  props:{
    business: {
      type: Object,
      default:null
    },
    showSites:{
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      loadingLandingPage: false,
      showNegativeReviewModal:false,
      negativeFormWorking:false,
      dataBusiness:this.business,
      submissionMessage:'',
      status:200,
      showReviewSites:this.showSites,
      contact:null,
      negativeReviewForm:{
        name:'',
        email:'',
        feedback:'',
        id:'',
        request_key:'rwg_pass'
      },
      business_id:null,
      employee_id:null
    }
  },
  metaInfo () {
    return {
      title: 'Review ' + this.dataBusiness?.business.business_name,
      meta: [
        { name: 'og:title', content: 'Review ' + this.dataBusiness?.business.business_name },
      ],

    }
  },
  computed: {
    starWidth(){
      return (this.dataBusiness.average / 5) * 100
    },
    reviewLink(){
      const placeId = this.dataBusiness.place_id;
      return 'https://search.google.com/local/writereview?placeid=' + placeId;
    }
  },

  mounted() {
    // This is for a l.reviewour.biz/r/ link for Lead Machine
    if(origin.includes('leadmachine.io') || origin.includes('https://l.reviewour.biz')){ //
      // https://l.reviewour.biz/linknotfound
      this.findLeadMachineRedirect();
    }else{
      // If we are dealing with a card, set a custom business ID we will parse in the API
      if(this.$route.params.card_id){
        this.business_id = 'card_'+ this.$route.params.card_id;
      }else{
        this.business_id = this.$route.params.uuid;
      }
      this.employee_id  = this.$route.params.employee_id ?? this.$route.query.eid;
      if(this.business === null) {
        this.markVisited();
        this.getWidget();
      }else{
        this.dataBusiness = this.business;
      }
    }
  },

  methods: {
    findLeadMachineRedirect(){
      this.$store
          .dispatch('landingPage/findLeadMachine', {unique_id:this.$route.params.uuid})
          .then((res) => {
            if(typeof res.data === 'object'){
              window.location.href = 'https://search.google.com/local/writereview?placeid=' + res.data.place_id;
            }else{
              window.location.href = 'https://l.reviewour.biz/linknotfound';
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    recordEmotions(positive = 0,negative = 0){
      this.$store
          .dispatch('landingPage/recordFeedback', {business_id:this.business_id, employee_id:this.employee_id, positive:positive, negative:negative, contact_id:this.$route.query.cid})
          .then((res) => {
            if(positive){
              if(this.dataBusiness.business.review_sites && this.dataBusiness.business.review_sites.length){
                this.showReviewSites = true;
              }else {
                window.location.href = this.reviewLink;
              }
            }
            if(negative){
              this.showNegativeReviewModal = true
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    markVisited(){
      this.$store
          .dispatch('landingPage/markVisited', {business_id:this.business_id, employee_id:this.employee_id})
          .then((res) => {

          })
          .catch((error) => {
            if(error.response.status){
              this.status = error.response.status;
              document.body.classList.add('gate-404');
              this.loadingLandingPage = false;
            }
            console.log(error)
          })
    },
    getWidget() {
      this.loadingLandingPage = true;
      this.$store
          .dispatch('landingPage/getReviewGate', {business_id: this.business_id, query_params:window.location.search.substring(1)})
          .then((res) => {
            this.dataBusiness = res.reviewGate;
            this.contact = res.contact;
            const queryParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(queryParams.entries());
            if(params.hasOwnProperty('gbp')){
              // Coming from text message
              if(params.gbp === '1'){
                // Send to Google Business Profile
                window.location.href = "https://www.google.com/maps/place/?q=" + (this.dataBusiness.business.business_name + "+" + this.dataBusiness.business.address + '+' + this.dataBusiness.business.city + "+" + this.dataBusiness.business.state + "+" + this.dataBusiness.business.zip).replace(/\s+/g, '+');
              }else{
                // Send to Review link
                if(this.dataBusiness.business.review_sites && this.dataBusiness.business.review_sites.length){
                  this.showReviewSites = true;
                  this.status = 200;
                  this.loadingLandingPage = false;
                }else {
                  window.location.href = this.reviewLink;
                }
              }
            }else{
              if(!this.dataBusiness.business.review_gate_enabled){
                if(this.dataBusiness.business.review_sites && this.dataBusiness.business.review_sites.length){
                  this.showReviewSites = true;
                  this.status = 200;
                  this.loadingLandingPage = false;
                }else {
                  window.location.href = this.reviewLink;
                }
              }else{
                this.status = 200;
                this.loadingLandingPage = false
              }
            }

          })
          .catch((error) => {
            if(error.response.status){
              this.status = error.response.status;
              document.body.classList.add('gate-404');
            }
            this.loadingLandingPage = false
          })
    },
    submitNegativeExperience(){
      this.negativeReviewForm.id = this.dataBusiness.business.id;
      this.negativeFormWorking = true;
      this.submissionMessage = '';
      this.$store
          .dispatch('landingPage/postNegativeExperience', this.negativeReviewForm)
          .then((res) => {
            this.negativeFormWorking = false;
            if(res.success){
              this.submissionMessage = res.message;
              this.negativeReviewForm.name = '';
              this.negativeReviewForm.email = '';
              this.negativeReviewForm.feedback = '';
            }
          })
          .catch((error) => {
            console.log(error);
            this.negativeFormWorking = false
          })
    }
  },
}
</script>
<style>
@import "https://fonts.googleapis.com/css2?family=Raleway&display=swap";
</style>
<style lang="scss" scoped>
.container{
  max-width: 810px;
}
  .header-row .container{
    background: #4472c4;
    padding:30px;
    h1{
      margin-top: 0;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
  .star-container {
    font-size: 15px;
    background: #538df4;
    border-radius:20px;
    align-items: center;
    padding: 0 3px 8px 12px;
    margin-right: 10px;
    .rating-count{
      margin-bottom: -9px;
      font-weight: bold;
      margin-right: 5px;
    }
    .star-count {
      display: inline-block;
      unicode-bidi: bidi-override;
      color: #888888;
      font-size: 23px !important;
      span {
        font-size: 23px !important;
        &:last-child{
          padding-right: 0;
        }
      }

      height: 25px;
      width: auto;
      margin: 0;
      position: relative;
      padding: 0;

      .rating-upper {
        color: #fff;
        padding: 0;
        position: absolute;
        z-index: 1;
        display: flex;
        top: 0;
        left: 0;
        overflow: hidden;
      }

      .rating-lower {
        padding: 0;
        display: flex;
        z-index: 0;
      }
    }
  }
  .review-count{
    font-weight: bold;
    font-size: 15px;
  }
  main{
    h2{
      color: #3b3c3c;
    }
    .main-text{
      max-width:570px;
      width: 100%;
      margin: 0 auto;
      font-size: 18px;
      color: #7b7c7b;
    }
    .thumbs{
      span{
        color: #7b7c7b;
      }
      > .d-flex{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        span{
          max-width: 300px;
          word-break: break-all;
        }
      }
      .thumbs-up{
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
      .thumbs-down{
        flex: 1;
        display: flex;
        button{
          appearance: none;
          background:none;
          border:0;
          font-weight: bold;
          span{
            max-width: 300px;
            word-break: break-all;
          }
        }
      }
    }
  }
  .footer-row{
    background: #d9d9d9;
    text-align: center;
    padding: 20px;
    color: #8d8d8d;
    font-weight: bold;
  }
  .neg-feedback {
    .modal-content{
      border-radius: 0;
      border: 0;
    }
    .feedback{
      text-align: center;
      margin-top: 10px;
      color: green;
    }
    .skip-gate {
      color: #7b7b7b;
      background: #d9d9d8;
      text-align: center;
      padding: 5px 10px;
      margin: 30px -16px -16px -16px;
      font-size: 14px;
      border-bottom: 2px solid #8a8a8b;

      a {
        color: #538ef4;
      }
    }
  }
  @media screen and (max-width:640px){
    .header-row .container {
      padding: 15px;
      h1 {
        font-size: 18px;
      }
      .star-container{
        font-size: 13px;
        .star-count{
          font-size: 18px !important;
          height: 20px;
          span{
            font-size: 18px !important;
          }
        }
      }
      .review-count{
        padding-top: 2px;
        font-size: 13px;
      }
    }
  main {
    h2{
      font-size: 22px;
    }
    .main-text{
      font-size: 14px;
    }
    .thumbs{
      span{
        font-size: 12px;
      }
    }
    .footer-row{
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 12px;
      padding: 10px 0;
      font-size: 10px;
      .col{
        padding: 0 5px;
      }
    }
  }
}
@media screen and (max-width:400px){
  main{
    .thumbs{
      .thumbs-up{
        justify-content: flex-start;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .thumbs-down{
        justify-content: flex-end;
        padding-right: 0 !important;
        padding-left: 0 !important;

      }
    }
  }
}
</style>
<style>
body.gate-404{
  padding-bottom: 0;
}
@media screen and (max-width:640px) {
  body {
    background: #fff;
  }
  .error-div{
    align-items: flex-start !important;
    padding-top: 80px !important;
  }
}
</style>
